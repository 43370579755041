<template>
  <div class="kunden">
    <div class="section">
      <div class="kunden__wrapper">
        <div class="kunden__content">
          <div class="kblock__h1 kblock__h1--black">Markenerfahrung</div>
          <div class="kblock__h2 ">Und viele mehr...</div>

          <div class="kunden__flex">
            <KundenPic v-for="(el, index) in 50 " :key="index" :imgpath="'assets/img/marken/' + index + '.jpg'" />

          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import KundenPic from "./KundenPic";
export default {
  components: { KundenPic },
  setup() {
    let path = "assets/img/svg/_referenzlogos/Referenzlogos-0";

    return {
      path,
    };
  },
};
</script>

<style></style>
