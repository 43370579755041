<template>
  <div class="projekt">
    <div class="projekt__wrapper">
      <div class="projekt__content">
        <div class="projekt__item">
          <div class="projekt__hl">projekte</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
};
</script>

<style lang="scss">

</style>
