<template>
  <div class="header--aspectratio" ref="header">
    <div class="header__wrapper" ref="headerWrapper">
      <VideoComponentSameAspekt
        :videopath="videopath"
        :imgposition="imgposition"
      />
    </div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import { ref } from "@vue/runtime-core";
import VideoComponentSameAspekt from "./VideoComponentSameAspekt.vue";
export default {
  components: { VideoComponentSameAspekt },
  props: { videopath: String, imgposition: String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const headerWrapper = ref(null);
    const header = ref(null);

    return { setBackGroundPath, headerWrapper, header };
  },
};
</script>

